import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import Logo from "@images/img/FastCompanyLogo.png"
import ScrollTopHeader from "../ScrollTopHeader/ScrollTopHeader"
import { convertToBgImage } from "gbimage-bridge"
import customDate from "@utils/customDate"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper"
import "swiper/swiper.scss"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

SwiperCore.use([Navigation, Autoplay, Pagination])

const PressMentions = ({ bgPressMentions, pressMentions, customers }) => {
  const [_pressMentions, setPressMentions] = useState([])

  useEffect(() => {
    setPressMentions(pressMentions)
  }, [])

  const image = convertToBgImage(
    getImage(bgPressMentions.childImageSharp.gatsbyImageData)
  )

  return (
    <BackgroundImage {...image} Tag="section" className="mainpost">
      <div className="press-mentions-container">
        <div className="carousel">
          <div className="mentions-cards">
            <Swiper
              tag="section"
              slidesPerView={1}
              spaceBetween={30}
              slidesPerGroup={1}
              loop={true}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={false}
              className="mySwiper"
            >
              {_pressMentions.map(({ node }) => {
                return (
                  <SwiperSlide key={node._id}>
                    <div className="mention">
                      {node.description && (
                        <p className="description">
                          <span>“{node.description}”</span>
                        </p>
                      )}
                      <a
                        target="_blank"
                        href={node.url}
                        rel="noopener noreferrer"
                      >
                        <div className="image-container">
                          <GatsbyImage
                            imgStyle={{ objectFit: "contain" }}
                            image={
                              node.customImage.image.asset?.gatsbyImageData
                            }
                            alt={
                              node?.description ||
                              node.customImage?.altText ||
                              "climate"
                            }
                          />
                        </div>
                      </a>
                      {node.title && (
                        <a
                          target="_blank"
                          href={node.url}
                          rel="noopener noreferrer"
                        >
                          <h2 className="title">{node.title}</h2>
                        </a>
                      )}
                      {node.pressName && node.publishedAt && (
                        <span>
                          {node.pressName}, {customDate(node.publishedAt)}
                        </span>
                      )}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PressMentions
